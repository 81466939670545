<template>
    <div class="contaner_p">
        <clone-tab :bgcShow="bgcShow" :bgcTitle="bgcTitle"></clone-tab>
        <div class="content_box">
            <van-steps direction="vertical" active-color="#0084FF" :active="0">
                <van-step>
                    <h3>信息提交</h3>
                    <p>查看信息 ></p>
                    <p>2016-07-12 12:40</p>
                </van-step>
                <van-step>
                    <h3>物料审核</h3>
                    <p>2016-07-11 10:00</p>
                </van-step>
                <van-step>
                    <h3>快件已发货</h3>
                    <p>2016-07-10 09:30</p>
                </van-step>
            </van-steps>
            <div class="cloneBtn">关闭申请</div>
        </div>
    </div>
</template>
<script type>
import cloneTab from "../../components/expenditure/cloneTab";
export default {
    data() {
        return {
            bgcShow: true,
            bgcTitle: '处理进度',
            show: false,
            radioConfig: {
                value: '',
                list: [
                    { checked: true, title: '增值兑换', ladel: 1 },
                    { checked: false, title: '分享补金兑换', ladel: 2 },
                ]
            }
        };
    },
    components: {
        cloneTab,
    },
    created() {
        document.title = "";

    },
    methods: {
        submitBtn() {
            this.show = true
        }
    }
};
</script>
<style lang="less">
.contaner_p {
    background-color: #F9F9FB;

    .content_box {
        width: 680px;
        // height: 1387px;
        min-height: 100vh;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
        border-radius: 20px 20px 20px 20px;
        margin: 35px auto 0;
        padding: 50px;
        box-sizing: border-box;
        position: relative;

        .cloneBtn {
            position: absolute;
            bottom: 100px;
            left: 115px;
            width: 450px;
            height: 80px;
            background: #F6F6F6 ;
            border-radius: 12px 12px 12px 12px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 28px;
            color: #1A1A1A;
            text-align: center;
            line-height: 80px;
        }
    }
}
</style>
